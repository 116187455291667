<template>
  <!-- 个人端已登录头部组件 -->
  <div class="el-logged-heard">
    <div class="space-between heard-mask">
      <div class="el-heard-left row-center font-lighter">
        <div class="text-blue font-bold font-weight-bolder">干纺织</div>
        <div
          class="el-heatd-text pointer"
          v-for="(item, index) in arr"
          :key="index"
          @click="selectNav(item.path)"
          :class="isSelect === item.title ? 'active' : ''"
        >
          {{ item.title }}
        </div>
        <!-- <div class="heatd-text pointer">职位</div>
      <div class="heatd-text pointer">企业</div> -->
      </div>
      <div class="heard-right row-center font-lighter">
        <div
          class="el-heatd-text pointer"
          :class="isSelect === '通知中心' ? 'active' : ''"
          @click="selectNav('/notification')"
        >
          通知中心
        </div>
        <div
          class="el-heatd-text pointer"
          :class="hasLogin == false ? 'hasLoginClass' : ''"
          @click="feedbackClick"
        >
          帮助反馈
        </div>
        <div
          class="el-heatd-text heatd-text-one pointer"
          :class="isSelectOne === '简历' ? 'active' : ''"
          @click="selectNav('/my-resume')"
          v-if="hasLogin"
        >
          简历
        </div>
        <div class="user-name row-center pointer" v-if="hasLogin" @click="heardClick">
          <span class="font-weight-bolder">
            {{ userDetail.name ? userDetail.name : (userDetail.user_name ? userDetail.user_name : userDetail.phone) }}</span
          >
          <img :src="userDetail.avatar_url ? userDetail.avatar_url : avatar" alt="" />
        </div>
        <div class="row login-register font-lighter" v-else>
          <div class="flex-center pointer" @click="navTo('/login', 2)">注册</div>
          <div class="flex-center pointer" @click="navTo('/login', 1)">登录</div>
        </div>
      </div>
      <div class="components-heard position-absolute" v-if="heardShow">
        <heard-top
          :backstage="false"
          @navToClick="navToClick"
          @logout="logout"
        ></heard-top>
      </div>
    </div>
    <!-- 意见反馈已登录的状态 -->
    <mask-model ref="mask" title="意见反馈" @submitClick="feedback" :interview="false" width="560px" padding="26px 30px 38px 30px">
      <div class="el-mask-fk">
        <div class="row mask-fk-center">
          <div class="center-text">内容描述：</div>
          <div class="center-textarea">
            <el-input type="textarea" v-model="query.content" placeholder="请简要描述你的问题和意见（必填）">
            </el-input>
          </div>
        </div>
        <div class="mask-fk-img row">
          <div class="">问题截图：</div>
          <div class="img-upload">
            <div class="upload-text">最多3张，支持JPG，JPEG，PNG格式（选填）</div>
            <div style="margin-top: 12px">
              <div class="el-logo-upload row">
                <div class="row">
                  <div
                    class="el-logo-style"
                    v-for="(item, index) in styleImgList"
                    :key="index"
                    @mouseenter="mouseenterImg(index)"
                    @mouseleave="mouseleaveImg"
                  >
                    <img :src="item" alt="" />
                    <div
                      class="el-list-posi-style"
                      v-if="imgIndex == index"
                      @click="deleteImgClickOne(index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
                <div v-if="styleImgList.length < 3">
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    accept=".jpg,.png,.jpeg"
                    :show-file-list="false"
                    :http-request="uploadMainImgTwo"
                  >
                    <i class="el-icon-plus avatar-uploader-icon">
                      <div class="el-upload__text">上传文件</div>
                    </i>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mssk-fk-code row" v-if="setObj && setObj.is_captcha==1">
          <div>验证码：</div>
          <div class="row code-img">
            <el-input placeholder="验证码" v-model="query.verify"></el-input>
            <img :src="imgUrl" alt="" class="el-img-mask pointer"  @click="getCaptcha" />
          </div>
        </div>
      </div>
    </mask-model>
    <!-- 意见反馈未登录的状态 -->
    <mask-model
      ref="notLogin"
      :titleShow="false"
      sureContent="确认"
      :btnCancel="false"
      width="480px"
    >
      <div class="login-not-center">
        <div>检测到您未登录,请先 <span class="text-blue pointer" @click="navTo('/login', 1)">登录/注册</span></div>
        <div>或</div>
        <div>扫一扫，请添加您的专属顾问</div>
        <img :src="kfObj.qrcode" alt="" v-if="kfObj" />
      </div>
    </mask-model>
  </div>
</template>

<script>
import heardTop from "./heardTop.vue";
import maskModel from "./mask.vue";
import { mapState, mapGetters } from "vuex";
import api from "../api/user";
import toolApi from "../api/tool"
export default {
  components: { heardTop, maskModel },
  computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userInfo", "userDetail"]),
    ...mapGetters(["hasLogin"]),
  },
  data() {
    return {
      isSelect: "首页",
      arr: [
        { title: "首页", path: "/" },
        { title: "职位", path: "/position" },
        { title: "企业", path: "/enterprise" },
      ],
      heardShow: false,
      isSelectOne: "",
      dialogImageUrl: "",
      dialogVisible: false,
      limit: 3,
      loginShow: true,
      avatar: require("@/assets/login/922028.png"),
      styleImgList: [],
      imgIndex: -1,
      query: {
        content: "",
        img_ids: [],
        verify:""
      },
      imgUrl:"",
      kfObj:null,
      setObj:null
    };
  },

  created() {
    this.setObj=JSON.parse(localStorage.getItem('setObj'))
  },

  mounted() {
    this.isSelect = this.$route.meta.title;
    this.kfObj=JSON.parse(localStorage.getItem('kfObj'))
    // 判断路由中是否存在titletext
    if (this.$route.meta.titleText) {
      this.isSelectOne = this.$route.meta.titleText;
    }
  },
  //监听路由的变化
  watch: {
    $route(to, from) {
      this.heardShow = false;
    },
  },
  methods: {
      // 获取图形验证码
    getCaptcha() {
      this.imgUrl =
        "https://ganfangzhi.com/" +
        "/api/common/getCaptcha" +
        "?" +
        new Date().getTime();
    },
    logout() {
      this.heardShow = false;
    },
    // 跳转至登录注册页面
    navTo(path, type) {
      this.$router.push({ path, query: { type } });
    },
    selectNav(path) {
      this.isSelect = this.$route.name;
      this.$router.push(path);
    },
    // 显示用户相关弹窗
    heardClick() {
      this.heardShow = !this.heardShow;
    },
    // 用户相关事件
    navToClick(type) {
      this.heardShow = false;
      // 依据type类型进行跳转事件，建议使用name跳转，使用name跳转必须在router中进行设置name且name值必须唯一
      // 判断当前用户是不是已经注册成为招聘者了，如果是让他直接跳转到企业端首页
      if (type === "Recruiter" && this.userDetail.is_enterprise !== 0) {
        api.toggleUser({ user_type: 2 }).then((res) => {
         if(res.code==200){
          this.$router.push({ name: "home" });
         }
        });
        
        return;
      }
      this.$router.push({ name: type });
    },

    // 帮助与反馈
    feedbackClick() {
      if (this.hasLogin) {
        this.getCaptcha()
        this.query.content = "";
        this.query.img_ids = [];
        this.styleImgList = [];
        this.imgIndex = -1;
        // 已登录
        this.$refs.mask.show();
      } else {
        // 未登录
        this.$refs.notLogin.show();
      }
    },
    //鼠标移除照片
    mouseleaveImg() {
      this.imgIndex = -1;
    },
    // 鼠标移入照片
    mouseenterImg(index) {
      this.imgIndex = index;
    },
    // 移除照片
    deleteImgClickOne(index) {
      this.styleImgList.splice(index, 1);
      this.query.img_ids.splice(index, 1);
    },
    // 上传企业风采
    uploadMainImgTwo(file) {
      if(file.file.type!="image/png" &&  file.file.type!="image/jpg" && file.file.type!="image/jpeg"){
        this.$util.msg('请上传png,jpg,jpeg格式的图片','error')
        return
      }
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      toolApi
        .uploadImg(data)
        .then((res) => {
          this.$util.msg(res.msg);
          this.styleImgList.push(res.data.file_path);
          this.query.img_ids.push(res.data.file_id);
        })
        .catch((err) => {});
    },
    // 意见反馈提交接口
    feedback(){
      if(this.query.content==''){
        this.$util.msg('请输入内容','warning')
        return
      }
       if (this.query.verify == "" && this.setObj.is_captcha==1) {
        this.$util.msg("请输入验证码", "warning");
        return;
      }
      toolApi.feedback(this.query).then(res=>{
        if(res.code==200){
          this.$util.msg('1-2 个工作日内会反馈')
          this.$refs.mask.hide()
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.el-logged-heard {
  width: 100%;
  // min-width: 1500px;
  height: 68px;
  background: #ffffff;
  padding: 0 190px;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1000;
  .heard-mask {
    width: 100%;
    height: 100%;
  }
  .active {
    color: #126bf9 !important;
  }
  .el-heard-left {
    .el-heatd-text {
      color: #666;
      margin-left: 40px;
    }
    div:nth-child(2) {
      margin-left: 3.625rem;
    }
  }
  .heard-right {
    .el-heatd-text {
      color: #666;
      margin-right: 40px;
    }
    .heatd-text-one {
      margin-right: 2.1875rem;
    }
    .hasLoginClass {
      margin-right: 3.625rem;
    }
    .user-name {
      color: #000000;
      img {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        margin-left: 10px;
      }
    }
    .login-register {
      div {
        width: 3.75rem;
        height: 1.875rem;
        border: 1px solid #126bf9;
        border-radius: 0.9375rem;
        color: #666;
      }
      div:first-child {
        margin-right: 1.25rem;
      }
    }
  }
  .components-heard {
    top: 4.5625rem;
    right: 11.875rem;
  }
  .el-mask-fk {
    margin-top: 26px;
    margin-bottom: 29px;
    color: #68687a;
    font-size: 14px;
    .mask-fk-center {
      margin-bottom: 1.75rem;
      .center-textarea {
        margin-left: 1.1875rem;
        /deep/.el-textarea {
          width: 390px;
          height: 120px;
        }
        /deep/ .el-textarea__inner {
          min-height: 120px !important;
        }
      }
    }
    .img-upload {
      margin-left: 1.1875rem;
      .upload-text {
        color: #9fa3af;
        font-size: 0.8125rem;
      }
      /deep/.el-upload--picture-card {
        width: 108px;
        height: 108px;
        line-height: 106px;
      }
      /deep/.el-upload-list__item {
        width: 108px;
        height: 108px;
      }
    }
    .mssk-fk-code {
      margin-bottom: 1.8125rem;
      .code-img {
        margin-left: 2.0625rem;
        /deep/.el-input {
          width: 270px;
        }
        /deep/.el-input__inner {
          border-radius: 0;
        }
        .el-img-mask {
          width: 140px;
          height: 40px;
        }
      }
    }
  }
  .login-not-center {
    margin-top: 3.625rem;
    margin-bottom: 1.625rem;
    text-align: center;
    font-size: 0.875rem;
    color: #999;
    div:nth-child(2) {
      line-height: 24px;
    }
    img {
      width: 8.5rem;
      height: 8.5rem;
      margin-top: 1.875rem;
    }
  }
}
.el-logo-upload {
  margin-top: 10px;
  margin-bottom: 33px;
  .el-logo-style {
    width: 85px;
    height: 85px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .el-list-posi-style {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      margin-right: 10px;
      color: #ffffff;
    }
  }
  /deep/ .el-upload {
    border: 1px dashed #e3e7ed;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .el-upload__text {
    font-size: 12px;
    margin-top: 12px;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 0.75rem;
    color: #8c939d;
  }
  .avatar {
    width: 5.3125rem;
    height: 5.3125rem;
    display: block;
  }
}
</style>
